import React from 'react';
import DashboardIcon from '@material-ui/icons/Dashboard';
import PeopleIcon from '@material-ui/icons/People';
import BusinessIcon from '@material-ui/icons/Business';
import InsertDriveFileIcon from '@material-ui/icons/InsertDriveFile';
import SettingsIcon from '@material-ui/icons/Settings';
import GroupAddIcon from '@material-ui/icons/GroupAdd';
import CollectionsIcon from '@material-ui/icons/Collections';
import { ROLES } from '../../../utils/constants';

const { SUPER_ADMIN, ORG_USER, ORG_ADMIN } = ROLES;

const sidebarItems = [
  {
    text: 'Dashboard',
    icon: <DashboardIcon />,
    link: '/dashboard',
    role: [ORG_ADMIN, ORG_USER],
  },
  {
    text: 'Users',
    icon: <PeopleIcon />,
    link: '/users',
    role: [ORG_ADMIN],
  },
  {
    text: 'Organizations',
    icon: <BusinessIcon />,
    link: '/organizations',
    role: [SUPER_ADMIN],
  },
  {
    text: 'Invoices',
    icon: <InsertDriveFileIcon />,
    link: '/invoices',
    role: [ORG_ADMIN, ORG_USER],
  },
  {
    text: 'Settings',
    icon: <SettingsIcon />,
    link: '/settings',
    role: [ORG_ADMIN],
  },
  {
    text: 'Customers',
    icon: <GroupAddIcon />,
    link: '/customers',
    role: [ORG_ADMIN],
  },
  {
    text: 'Products',
    icon: <CollectionsIcon />,
    link: '/products',
    role: [ORG_ADMIN],
  },
];

export default sidebarItems;
