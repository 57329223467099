import Dashboard from '../containers/Dashboard/loadable';
import Organization from '../containers/Organization/loadable';
import EditOrganization from '../containers/EditOrganization/loadable';
import OrganizationList from '../containers/OrganizationList/loadable';
import Login from '../containers/Login/loadable';
import ForgotPassword from '../containers/ForgotPassword/loadable';
import ResetPassword from '../containers/ResetPassword/loadable';
import { ROLES } from '../utils/constants';
import Users from '../containers/Users/loadable';
import AddUser from '../containers/AddUser/loadable';
import EditUser from '../containers/EditUser/loadable';
import CreateInvoice from '../containers/CreateInvoice/loadable';
import InvoiceList from '../containers/InvoiceList/loadable';
import EditInvoice from '../containers/EditInvoice/loadable';
import CustomizeInvoice from '../containers/CustomizeInvoice/loadable';
import InvoiceDetails from '../containers/InvoiceDetails/loadable';
import Profile from '../containers/Profile/loadable';
import EditProfile from '../containers/EditProfile/loadable';
import InvoiceView from '../containers/InvoiceView/loadable';
import CreateCustomer from '../containers/CreateCustomer/loadable';
import CustomersList from '../containers/CustomersList/loadable';
import EditCustomer from '../containers/EditCustomer/loadable';
import BulkCustomers from '../containers/BulkCustomers/loadable';
import CreateProduct from '../containers/CreateProduct/loadable';
import Products from '../containers/Products/loadable';
import EditProduct from '../containers/EditProduct/loadable';

const routeTypes = { public: 'public', private: 'private', auth: 'auth' };
const { SUPER_ADMIN, ORG_ADMIN, ORG_USER } = ROLES;

export const routeArray = [
  {
    path: '/',
    component: Login,
    exact: true,
    breadCrumbKey: 'login',
    routeType: routeTypes.auth,
  },
  {
    path: '/forgot-password',
    component: ForgotPassword,
    exact: true,
    breadCrumbKey: 'forgot password',
    routeType: routeTypes.auth,
  },
  {
    path: '/reset-password/:token',
    component: ResetPassword,
    exact: true,
    breadCrumbKey: 'reset password',
    routeType: routeTypes.auth,
  },
  {
    path: '/profile',
    component: Profile,
    exact: true,
    routeType: routeTypes.private,
  },
  {
    path: '/edit-profile',
    component: EditProfile,
    exact: true,
    routeType: routeTypes.private,
  },
  {
    path: '/dashboard',
    component: Dashboard,
    exact: true,
    routeType: routeTypes.private,
    roles: [ORG_ADMIN, ORG_USER],
  },
  {
    path: '/add-new-organization',
    component: Organization,
    exact: true,
    routeType: routeTypes.private,
    roles: [SUPER_ADMIN],
  },
  {
    path: '/organizations',
    component: OrganizationList,
    exact: true,
    routeType: routeTypes.private,
    roles: [SUPER_ADMIN],
  },
  {
    path: '/organization/:id',
    component: EditOrganization,
    exact: true,
    routeType: routeTypes.private,
    roles: [SUPER_ADMIN],
  },
  {
    path: '/add-new-user',
    component: AddUser,
    exact: true,
    routeType: routeTypes.private,
    roles: [ORG_ADMIN],
  },
  {
    path: '/users',
    component: Users,
    exact: true,
    routeType: routeTypes.private,
    roles: [ORG_ADMIN],
  },
  {
    path: '/user/:id',
    component: EditUser,
    exact: true,
    routeType: routeTypes.private,
    roles: [ORG_ADMIN],
  },
  {
    path: '/invoices',
    component: InvoiceList,
    exact: true,
    routeType: routeTypes.private,
    roles: [ORG_ADMIN, ORG_USER],
  },
  {
    path: '/add-new-invoice',
    component: CreateInvoice,
    exact: true,
    routeType: routeTypes.private,
    roles: [ORG_ADMIN],
  },
  {
    path: '/invoice/:id',
    component: EditInvoice,
    exact: true,
    routeType: routeTypes.private,
    roles: [ORG_ADMIN],
  },
  {
    path: '/invoice-view/:id',
    component: InvoiceView,
    exact: true,
    routeType: routeTypes.private,
    roles: [ORG_ADMIN, ORG_USER],
  },
  {
    path: '/settings',
    component: CustomizeInvoice,
    exact: true,
    routeType: routeTypes.private,
    roles: [ORG_ADMIN],
  },
  {
    path: '/invoice-details/:uuid',
    component: InvoiceDetails,
    exact: true,
    routeType: routeTypes.public,
    roles: [ORG_ADMIN],
  },
  {
    path: '/create-customer',
    component: CreateCustomer,
    exact: true,
    routeType: routeTypes.private,
    roles: [ORG_ADMIN],
  },
  {
    path: '/add-customers',
    component: BulkCustomers,
    exact: true,
    routeType: routeTypes.private,
    roles: [ORG_ADMIN],
  },
  {
    path: '/customers',
    component: CustomersList,
    exact: true,
    routeType: routeTypes.private,
    roles: [ORG_ADMIN],
  },
  {
    path: '/customers/:id',
    component: EditCustomer,
    exact: true,
    routeType: routeTypes.private,
    roles: [ORG_ADMIN],
  },
  {
    path: '/create-product',
    component: CreateProduct,
    exact: true,
    routeType: routeTypes.private,
    roles: [ORG_ADMIN],
  },
  {
    path: '/products',
    component: Products,
    exact: true,
    routeType: routeTypes.private,
    roles: [ORG_ADMIN],
  },
  {
    path: '/products/:id',
    component: EditProduct,
    exact: true,
    routeType: routeTypes.private,
    roles: [ORG_ADMIN],
  },
];
