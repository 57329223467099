import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import propTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import {
  Box,
  Drawer,
  Toolbar,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  Divider,
} from '@material-ui/core';
import { useAuthContext } from '../../context/authContext';
import { colors } from '../../theme/colors';
import { HOME_PAGE_LINK } from '../../utils/constants';
import Logo from '../../assets/images/logo.png';

const drawerWidth = 263;

const useStyles = makeStyles((theme) => ({
  drawerPaper: {
    width: drawerWidth,
  },
  drawerContainer: {
    overflow: 'auto',
    marginTop: theme.spacing(8),
  },
  sideBarItem: {
    width: drawerWidth - 60,
    borderTop: `1px solid ${colors.borderColor}`,
    borderBottom: `1px solid ${colors.borderColor}`,
    borderTopRightRadius: '20px',
    borderBottomRightRadius: '20px',
    backgroundColor: colors.primary,
    '& .MuiTypography-root, .MuiListItemText-primary, .MuiTypography-body1, .MuiTypography-displayBlock':
      {
        color: colors.textColor.light,
      },
    '& .MuiListItemIcon-root': {
      color: colors.textColor.light,
    },
    '& .MuiListItemIcon-root, .MuiSvgIcon-root': {
      fontSize: '32px',
    },
  },
  toolbar: {
    height: '90px',
  },
  listItem: {
    '&:hover': {
      width: drawerWidth - 60,
      borderTopRightRadius: '20px',
      borderBottomRightRadius: '20px',
    },
  },
  listItemIcon: {
    fontSize: '52px',
    color: colors.textColor.lightBlue,
  },
}));

const SideDrawer = ({ open, variant, handleDrawerToggle, drawerItems }) => {
  const classes = useStyles();
  const { pathname } = useLocation();
  const {
    user: {
      data: { role },
    },
  } = useAuthContext();

  return (
    <>
      <Drawer
        open={open}
        variant={variant}
        onClose={handleDrawerToggle}
        classes={{
          paper: classes.drawerPaper,
        }}
        ModalProps={{
          keepMounted: true, // Better open performance on mobile.
        }}
      >
        <Toolbar className={classes.toolbar}>
          <Link to={HOME_PAGE_LINK[role]}>
            <img src={Logo} width={250} alt="PARISH" />
          </Link>
        </Toolbar>
        <Divider />
        <Box className={classes.drawerContainer}>
          <List>
            {drawerItems.map((item, idx) => (
              <Box
                key={idx}
                component="div"
                className={item.link === pathname && classes.sideBarItem}
              >
                {item?.role && item?.role.indexOf(role) !== -1 && (
                  <ListItem
                    button
                    key={item}
                    component={Link}
                    to={item.link}
                    className={classes.listItem}
                  >
                    <ListItemIcon className={classes.listItemIcon}>
                      {item.icon}
                    </ListItemIcon>
                    <ListItemText primary={item.text} />
                  </ListItem>
                )}
              </Box>
            ))}
          </List>
        </Box>
      </Drawer>
    </>
  );
};

SideDrawer.propTypes = {
  open: propTypes.bool,
  variant: propTypes.string,
  handleDrawerToggle: propTypes.func,
  drawerItems: propTypes.instanceOf(Array),
};

SideDrawer.defaultProps = {
  open: true,
  variant: 'persistent',
  handleDrawerToggle: propTypes.func,
  drawerItems: [],
};
export default SideDrawer;
