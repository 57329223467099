import React from 'react';
import { Box } from '@material-ui/core';
import { colors } from '../../theme/colors';

const BackgroundWrapper = ({ children }) => (
  <>
    <Box bgcolor={colors.background.light} p={5} borderRadius={10}>
      {children}
    </Box>
  </>
);

export default BackgroundWrapper;
