import React from 'react';
import InputMask from 'react-input-mask';
import { Input } from '..';

const MaskInput = (props) => (
  <InputMask
    maskChar=""
    mask={props.mask}
    value={props.value}
    onChange={props.onChange}
    {...props}
  >
    {(inputProps) => (
      <Input variant="outlined" {...inputProps} disableUnderline />
    )}
  </InputMask>
);

export default MaskInput;
