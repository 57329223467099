import React from 'react';
import clsx from 'clsx';
import propTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import {
  AppBar,
  Box,
  CssBaseline,
  Menu,
  MenuItem,
  Hidden,
  Toolbar,
  IconButton,
} from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import PostAddRoundedIcon from '@material-ui/icons/PostAddRounded';
import BusinessIcon from '@material-ui/icons/Business';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import { useAuthContext } from '../../../context/authContext';
import { colors } from '../../../theme/colors';
import { H2, BodyTextLarge, RedirectButton } from '../../index';
import { formatUserRoleText, useSharedState } from '../../../utils/helpers';
import Show from '../../show';
import { ROLES } from '../../../utils/constants';
import UserAvatarIcon from '../../../assets/images/useravatar.png';

const useStyles = makeStyles((theme) => ({
  appBar: {
    // zIndex: theme.zIndex.drawer + 1,
    paddingLeft: theme.spacing(5),
    paddingRight: theme.spacing(5),
    backgroundColor: colors.background.primary,
    height: '90px',
    justifyContent: 'center',
    '& .MuiSvgIcon-colorPrimary': {
      color: colors.textColor.gray,
    },
    '& .MuiSvgIcon-colorPrimary:hover': {
      background: 'none',
    },
  },
  menuButton: {
    marginLeft: theme.spacing(62),
    [theme.breakpoints.down('sm')]: {
      marginLeft: 0,
    },
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  title: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    flexGrow: 1,
  },
  contentShift: {
    marginLeft: theme.spacing(0),
  },
  headerButton: {
    borderRadius: '20px !important',
    height: '60px !important',
    fontWeight: 'bold',
    [theme.breakpoints.down('sm')]: {
      fontSize: 13,
      width: 137,
    },
  },
  avatar: {
    [theme.breakpoints.down('sm')]: {
      width: 50,
    },
  },
  username: {
    [theme.breakpoints.down('sm')]: {
      fontSize: 10,
    },
  },
  roleText: {
    color: colors.textColor.lightGray,
    [theme.breakpoints.down('sm')]: {
      fontSize: 10,
    },
  },
  orgNameText: {
    color: colors.textColor.lightBlue,
  },
  headerTitle: {
    letterSpacing: 1,
    color: colors.textColor.darkBlue,
  },
}));

const Header = ({
  open,
  isMobileOpen,
  handleDrawerToggle,
  handleMobileDrawerToggle,
}) => {
  const history = useHistory();
  const classes = useStyles();
  const {
    user: {
      data: { name, role, orgName },
    },
    setUser,
  } = useAuthContext();

  const [state] = useSharedState('title');

  const [anchorEl, setAnchorEl] = React.useState(null);
  const isOpen = Boolean(anchorEl);

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleNavigateToProfile = () => {
    history.push('/profile');
    handleClose();
  };

  return (
    <>
      <CssBaseline />
      <AppBar elevation={0} position="fixed" className={classes.appBar}>
        <Toolbar>
          {/* Menu Button for Mobile Screens */}
          <Hidden smUp implementation="css">
            <IconButton
              color="inherit"
              aria-label="open drawer"
              onClick={handleMobileDrawerToggle}
              edge="start"
              className={clsx(classes.menuButton, isMobileOpen && classes.hide)}
            >
              <MenuIcon color="primary" />
            </IconButton>
          </Hidden>

          {/* Menu Button for large screens */}
          <Hidden xsDown implementation="css">
            <IconButton
              color="inherit"
              aria-label="open drawer"
              onClick={handleDrawerToggle}
              edge="start"
              className={clsx(classes.menuButton, open && classes.hide, {
                [classes.contentShift]: !open,
              })}
            >
              <MenuIcon color="primary" style={{ fontSize: '40px' }} />
            </IconButton>
          </Hidden>

          <Box className={classes.title}>
            <Box
              width={[0, 300]}
              display="flex"
              alignItems="center"
              justifyContent="space-between"
            >
              <Hidden smDown implementation="css">
                <Box>
                  <H2 className={classes.headerTitle}>
                    <strong>{state}</strong>
                  </H2>
                  <Show IF={role !== ROLES.SUPER_ADMIN}>
                    <BodyTextLarge className={classes.orgNameText}>
                      Welcome to {orgName}
                    </BodyTextLarge>
                  </Show>
                </Box>
              </Hidden>
            </Box>
            <Box display="flex" alignItems="center">
              <Hidden xsDown implementation="css">
                <Show IF={role === ROLES.ORG_ADMIN}>
                  <Box mr={15}>
                    <RedirectButton
                      title="New Invoice"
                      variant="outlined"
                      path="/add-new-invoice"
                      startIcon={
                        <PostAddRoundedIcon style={{ fontSize: '30px' }} />
                      }
                      className={classes.headerButton}
                    />
                  </Box>
                </Show>
              </Hidden>
              <Hidden xsDown implementation="css">
                <Show IF={role === ROLES.SUPER_ADMIN}>
                  <Box mr={15}>
                    <RedirectButton
                      title="New Organization"
                      variant="outlined"
                      path="/add-new-organization"
                      startIcon={<BusinessIcon style={{ fontSize: '30px' }} />}
                      className={classes.headerButton}
                    />
                  </Box>
                </Show>
              </Hidden>
              <Hidden xsDown implementation="css">
                <Box align="right" mr={5}>
                  <BodyTextLarge className={classes.username}>
                    {name}
                  </BodyTextLarge>
                  <BodyTextLarge className={classes.roleText}>
                    {formatUserRoleText(role)}
                  </BodyTextLarge>
                </Box>
              </Hidden>

              <Box display="flex" alignItems="center">
                <img
                  src={UserAvatarIcon}
                  alt="User Avatar"
                  className={classes.avatar}
                />

                <IconButton onClick={handleMenu}>
                  <ArrowDropDownIcon color="secondary" />
                </IconButton>
              </Box>
            </Box>
          </Box>

          <Menu
            id="menu-appbar"
            anchorEl={anchorEl}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            keepMounted
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            open={isOpen}
            onClose={handleClose}
          >
            <MenuItem onClick={handleNavigateToProfile}>Profile</MenuItem>
            <MenuItem
              onClick={() =>
                setUser({ isAuthenticated: false, token: null, data: {} })
              }
            >
              Logout
            </MenuItem>
          </Menu>
        </Toolbar>
      </AppBar>
    </>
  );
};

Header.propTypes = {
  open: propTypes.bool.isRequired,
  isMobileOpen: propTypes.bool.isRequired,
  handleDrawerToggle: propTypes.func.isRequired,
  handleMobileDrawerToggle: propTypes.func.isRequired,
};

export default Header;
