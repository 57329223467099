import React from 'react';
import { Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { H1 } from '..';
import Logo from '../../assets/images/logo.png';
import BgPattern from '../../assets/images/bgpattern.png';
import VectorImage from '../../assets/images/invoicevector.png';
import { colors } from '../../theme/colors';

const useStyles = makeStyles((theme) => ({
  parentBox: {
    width: '100%',
    height: '100vh',
    overflowY: 'hidden',
    overflowX: 'hidden',
    display: 'flex',
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(0),
    },
  },

  leftBox: {
    backgroundColor: colors.background.white,
    padding: theme.spacing(15),
    paddingBottom: theme.spacing(50),
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(10),
      paddingBottom: theme.spacing(30),
    },
  },

  rightBox: {
    backgroundImage: `url(${BgPattern})`,
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    [theme.breakpoints.down('xs')]: {
      display: 'none',
    },
  },

  rightBoxHeading: {
    position: 'relative',
    top: '4.5rem',
  },

  rightImageBox: {
    marginTop: '-50px',
  },

  rightBoxImage: {
    [theme.breakpoints.down('md')]: {
      width: '100%',
    },
  },

  logo: {
    [theme.breakpoints.down('md')]: {
      width: '300px',
    },
  },
}));

const AuthLayOut = ({ children }) => {
  const classes = useStyles();
  return (
    <Box className={classes.parentBox}>
      <Box className={classes.leftBox} width={['100%', '40%']}>
        <Box>
          <img src={Logo} alt="PARISH" className={classes.logo} />
        </Box>
        {children}
      </Box>

      <Box width="60%" className={classes.rightBox}>
        <Box align="center">
          <Box align="center" className={classes.rightBoxHeading}>
            <H1 style={{ color: colors.textColor.darkBlue }}>
              INVOICING PORTAL
            </H1>
          </Box>
          <Box className={classes.rightImageBox}>
            <img
              src={VectorImage}
              alt="vector"
              className={classes.rightBoxImage}
            />
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default AuthLayOut;
