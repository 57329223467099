export const LOCAL_STORAGE_ENTRIES = { user: 'user' };
export const ROLES = {
  SUPER_ADMIN: 'superAdmin',
  ORG_ADMIN: 'orgAdmin',
  ORG_USER: 'orgUser',
};

export const API_ENDPOINTS = {
  ORGANIZATION: '/organization',
  USERS: '/users',
  INVOICES: '/invoices',
  SETTINGS: '/settings',
  PAYMENTS: '/payments',
  DASHBOARD: '/dashboard',
  CUSTOMERS: '/customers',
  PRODUCTS: '/products',
};

export const USER_ROLES = [
  { label: 'Admin', value: 'orgAdmin' },
  { label: 'User', value: 'orgUser' },
];

export const INVOICE_STATUS = [
  { label: 'All Invoices', value: '' },
  { label: 'Draft', value: 'draft' },
  { label: 'Sent', value: 'sent' },
  { label: 'Cancel', value: 'cancel' },
  { label: 'Paid', value: 'paid' },
];

export const PAGE_SIZE = 10;

export const STATUS = {
  PAID: 'paid',
  CANCEL: 'cancel',
  SENT: 'sent',
  DRAFT: 'draft',
  PENDING: 'pending',
};

export const HOME_PAGE_LINK = {
  [ROLES.SUPER_ADMIN]: '/organizations',
  [ROLES.ORG_ADMIN]: '/dashboard',
  [ROLES.ORG_USERE]: '/dashboard',
};

export const PAYMENT_TYPES = {
  CC: 'cc',
  CK: 'ck',
};

export const PAYMENT_METHODS = {
  cc: 'creditcard',
  ck: 'check',
};

export const MAX_UPLOADABLE_IMAGE_SIZE_IN_MBS = 10; // Mb
export const MIN_UPLOADABLE_IMAGE_SIZE_IN_MBS = 0.1; // Mb
export const MIN_UPLOADABLE_FILE_SIZE_IN_MBS = 0; // Mb
export const MAX_UPLOADABLE_FILE_SIZE_IN_MBS = 100; // Mb
export const FILE_ACCEPT_TYPES = {
  imageFiles: 'image/x-png,image/jpeg,image/jpg',
};
