import React from 'react';
import PropTypes from 'prop-types';
import { Route } from 'react-router-dom';
import AuthLayout from '../authLayout';

const AuthRoute = ({ component: Component, ...rest }) => (
  <>
    <AuthLayout>
      <Route {...rest} render={(props) => <Component {...props} />} />
    </AuthLayout>
  </>
);

AuthRoute.propTypes = {
  component: PropTypes.elementType.isRequired,
};

export default AuthRoute;
