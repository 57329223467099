import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import { BodyTextSmall } from '../typography';

const useStyles = makeStyles({
  root: { textDecoration: 'none' },
});
function Index({ color, value, to, ...props }) {
  const classes = useStyles();

  return (
    <Link to={to} className={classes.root} {...props}>
      <BodyTextSmall color={color}>{value}</BodyTextSmall>
    </Link>
  );
}

Index.propTypes = {
  color: PropTypes.string,
  value: PropTypes.string,
  to: PropTypes.string,
};

Index.defaultProps = {
  color: 'textPrimary',
  value: 'Link',
  to: '/',
};
export default Index;
