import React, { memo, useEffect, useRef, useState } from 'react';
import { FormHelperText, Box } from '@material-ui/core';
import CancelIcon from '@material-ui/icons/Cancel';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { useField } from 'formik';
import Button from '../muiButton';
import { MAX_UPLOADABLE_IMAGE_SIZE_IN_MBS } from '../../utils/constants';
import { toastMessage } from '../../utils/helpers';

const useStyles = makeStyles((theme) => ({
  span: {
    position: 'absolute',
    right: theme.spacing(-2),
    top: theme.spacing(-2),
  },
  icon: {
    cursor: 'pointer',
  },
  img: {
    width: 100,
    height: 100,
    objectFit: 'cover',
  },
}));

function FormikFileInput({
  loading,
  buttonText,
  setFieldValue,
  acceptTypes,
  values,
  invoiceMedia,
  setInvoiceMedia,
  handleRemoveImage,
  ...props
}) {
  const [, meta] = useField(props);
  const classes = useStyles();
  const selectedFile = useRef(null);
  const handleUploadFile = () => {
    selectedFile.current.click();
  };
  const [error, setError] = useState(undefined);

  useEffect(() => {
    if (error) {
      toastMessage(error, 'error');
    }
  }, [error]);

  const handleCaptureFile = (files = []) => {
    if (invoiceMedia.length >= 5) {
      toastMessage("You can't upload more than 5 images");
      return;
    }
    if (files.length) {
      Array.from(files)?.forEach((file) => {
        const fileSizeInMB = parseInt(file?.size / (1024 * 1024), 10);
        if (fileSizeInMB >= MAX_UPLOADABLE_IMAGE_SIZE_IN_MBS) {
          setError('Error: File size too large');
        } else if (['application/x-msdownload'].includes(file?.type)) {
          setError('Error: Executable files are not allowed!');
        } else {
          let media = [...invoiceMedia];
          const imgs = Array.from(files)?.map((item) => ({
            file: item,
            preview: URL.createObjectURL(item),
          }));
          media = media.concat(imgs);
          setInvoiceMedia(media);
          setFieldValue(props.name, files);
        }
      });
    }
  };

  return (
    <Box>
      <Box display="flex">
        {invoiceMedia?.map((item, index) => (
          <Box width={100} height={100} mb={2} ml={2} position="relative">
            <Box component="span" className={classes.span}>
              <CancelIcon
                color="primary"
                className={classes.icon}
                onClick={() => handleRemoveImage(index)}
              />
            </Box>
            <img src={item.preview} alt="logo" className={classes.img} />
          </Box>
        ))}
      </Box>
      <input
        type="file"
        multiple
        accept={acceptTypes}
        ref={selectedFile}
        onChange={({ currentTarget }) => {
          handleCaptureFile(currentTarget.files);
        }}
        hidden
        disabled={loading}
        {...props}
      />
      <Button
        value="Upload Invoice Image"
        color="primary"
        onClick={handleUploadFile}
      />
      {meta.touched && meta.error ? (
        <Box ml={2}>
          <FormHelperText error>{meta.error}</FormHelperText>
        </Box>
      ) : null}
    </Box>
  );
}

FormikFileInput.propTypes = {
  buttonText: PropTypes.string,
  setFieldValue: PropTypes.func,
  values: PropTypes.object,
};

export default memo(FormikFileInput);
