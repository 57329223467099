import { createTheme, responsiveFontSizes } from '@material-ui/core/styles';
import { colors } from './colors';

const theme = createTheme({
  overrides: {
    MuiCssBaseline: {
      '@global': {
        html: {
          WebkitFontSmoothing: 'auto',
        },
      },
    },
  },
  palette: {
    primary: {
      main: colors.primary,
      light: colors.light,
    },
    secondary: {
      main: colors.secondary,
    },
    background: { default: colors.background.primary },
    text: colors.textColor,
  },
  spacing: (factor) => `${0.25 * factor}rem`,
  typography: {
    fontFamily: ['RobotoRegular', 'RobotoMedium'],
    h1: {
      fontSize: '34px',
      color: colors.textColor.primary,
      fontWeight: 700,
    },
    h2: {
      fontSize: '28px',
      color: colors.textColor.primary,
      fontWeight: 500,
      fontFamily: 'RobotoMedium',
    },
    h3: {
      fontSize: '22px',
      color: colors.textColor.primary,
      fontWeight: 500,
    },
    h4: {
      fontSize: '20px',
      color: colors.textColor.primary,
      fontWeight: 500,
    },
    h5: {
      fontSize: '18px',
      color: colors.textColor.primary,
      fontWeight: 500,
    },
    h6: {
      fontSize: '16px',
      color: colors.textColor.primary,
      fontWeight: 500,
    },

    body1: {
      fontWeight: 200,
      fontSize: '1rem',
      color: colors.textColor.primary,
      fontFamily: 'RobotoRegular',
    },
    body2: {
      fontWeight: 200,
      fontSize: '1rem',
      color: colors.textColor.primary,
      fontFamily: 'RobotoRegular',
    },
    button: {
      textTransform: 'capitalize',
      fontWeight: 500,
      fontSize: '1rem',
      height: '38px',
      borderRadius: '5px',
      fontFamily: 'RobotoRegular',
      color: '#fff',
      // background: colors.background.primary,
    },
  },
});
const updatedTheme = responsiveFontSizes(theme);
export default updatedTheme;
