import React from 'react';
import RadioGroup from '@material-ui/core/RadioGroup';
import MUIRadio from '@material-ui/core/Radio';
import { useField } from 'formik';
import { FormControlLabel, FormHelperText } from '@material-ui/core';

const FormikRadioGroup = ({ name, options, defaultValue, ...props }) => {
  const [field, meta] = useField(name);

  return (
    <>
      <RadioGroup row {...field} {...props} value={field.value || defaultValue}>
        {options.map((option) => (
          <FormControlLabel
            control={<MUIRadio value={option.value} />}
            label={option.label}
          />
        ))}
      </RadioGroup>
      {meta.touched && meta.error && (
        <FormHelperText error>{meta.error}</FormHelperText>
      )}
    </>
  );
};

export default FormikRadioGroup;
