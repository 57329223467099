import React from 'react';
import PropTypes from 'prop-types';
import { Box, CircularProgress } from '@material-ui/core';

const Spinner = ({ size }) => (
  <>
    <Box display="flex" flex="1" justifyContent="center" alignItems="center">
      <CircularProgress size={size} />
    </Box>
  </>
);

Spinner.propTypes = {
  size: PropTypes.number,
};

Spinner.defaultProps = {
  size: 40,
};
export default Spinner;
