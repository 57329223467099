import React from 'react';
import { CssBaseline } from '@material-ui/core';
import { ThemeProvider } from '@material-ui/core/styles';
import { ToastContainer } from 'react-toastify';
import theme from './theme';
import { AuthProvider } from './context/authContext';
import Routes from './routes';
import 'react-toastify/dist/ReactToastify.css';

const App = () => (
  <AuthProvider>
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Routes />
      <ToastContainer />
    </ThemeProvider>
  </AuthProvider>
);

export default App;
