import React from 'react';
import PropTypes from 'prop-types';
import { Box, FormHelperText } from '@material-ui/core';
import Select from 'react-select';
import { BodyTextLarge } from '../typography';

const customStyles = {
  control: (base) => ({
    ...base,
    height: 60,
    borderRadius: '20px',
  }),
};

const AutoComplete = ({
  label,
  name,
  options,
  onChange,
  onBlur,
  touched,
  errors,
  ...props
}) => {
  const handleChange = ({ value }) => {
    onChange(name, value);
  };

  const handleBlur = () => {
    onBlur(name, true);
  };

  return (
    <>
      {label && (
        <Box mb={2}>
          <BodyTextLarge>{label}</BodyTextLarge>
        </Box>
      )}
      <Select
        options={options}
        onChange={handleChange}
        onBlur={handleBlur}
        styles={customStyles}
        {...props}
      />
      {touched[name] && errors[name] && (
        <FormHelperText error>{errors[name]}</FormHelperText>
      )}
    </>
  );
};

AutoComplete.propTypes = {
  label: PropTypes.string,
  name: PropTypes.string.isRequired,
  options: PropTypes.array.isRequired,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  touched: PropTypes.object,
  errors: PropTypes.object,
};

export default AutoComplete;
