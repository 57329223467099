import React from 'react';
import { Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { Spinner } from '..';

const useStyles = makeStyles((theme) => ({
  button: {
    height: '45px',
    borderRadius: '15px',
    fontSize: theme.typography.button.fontSize,
    fontWeight: theme.typography.button.fontWeight,
    fontFamily: theme.typography.button.fontFamily,
  },
}));
const MuiButton = ({ value, isLoading, ...props }) => {
  const classes = useStyles();

  return (
    <Button
      className={classes.button}
      {...props}
      startIcon={isLoading && <Spinner size={20} />}
      disabled={isLoading}
    >
      {value}
    </Button>
  );
};
export default MuiButton;

MuiButton.propTypes = {
  variant: PropTypes.string,
  color: PropTypes.string,
  isLoading: PropTypes.bool,
};
MuiButton.defaultProps = {
  variant: 'contained',
  color: 'primary',
};
