import React from 'react';
import { DataGrid, GridOverlay } from '@material-ui/data-grid';
import { Alert } from '@material-ui/lab';
import { Box } from '@material-ui/core';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    '& .MuiDataGrid-root': {
      border: 'none',
    },
    '& .MuiDataGrid-columnsContainer': {
      color: theme.palette.text.primary,
      maxHeight: '70px !important',
      '& .MuiCheckbox-root': {
        color: theme.palette.secondary,
      },
    },
    '& .MuiDataGrid-columnSeparator': { display: 'none' },
    '& .MuiDataGrid-root .MuiDataGrid-columnHeader:focus,.MuiDataGrid-columnHeader:focus-within, .MuiDataGrid-root .MuiDataGrid-cell:focus,.MuiDataGrid-cell:focus-within':
      {
        outline: 'none',
      },
    '& .MuiDataGrid-columnHeaderTitle': {
      fontWeight: 'bold',
    },
  },
  gridOverlay: {
    zIndex: 100,
    height: '70px',
  },
  label: {
    marginTop: theme.spacing(1),
    width: '100%',
  },
}));

function CustomNoRowsOverlay() {
  const classes = useStyles();
  return (
    <GridOverlay className={classes.gridOverlay}>
      <Box className={classes.label}>
        <Alert severity="error">No data found</Alert>
      </Box>
    </GridOverlay>
  );
}

export default function DataTable({
  count,
  rows,
  columns,
  pageSize,
  setPageSize,
  page,
  setPage,
  paginationMode,
  checkboxSelection,
  selected,
  setSelected,
  ...props
}) {
  const classes = useStyles(props);

  const customColumns = columns?.map((val) => {
    const column = val;
    column.sortable = false;
    column.disableColumnMenu = true;
    return column;
  });

  const handleChangePageSize = (params) => {
    const rowsPerPage = params;
    setPageSize(rowsPerPage);
  };

  const handleChangePage = (e) => {
    setPage(e);
  };

  const handleSelectedRows = (selectionModel) => {
    setSelected(selectionModel);
  };

  return (
    <Box className={classes.root}>
      <DataGrid
        // className={classes.root}
        pagination
        paginationMode={paginationMode}
        rows={rows}
        rowHeight={70}
        columns={customColumns}
        pageSize={pageSize}
        onPageSizeChange={handleChangePageSize}
        disableSelectionOnClick
        rowsPerPageOptions={[5, 10, 20]}
        autoHeight={!props.height}
        components={{
          NoRowsOverlay: CustomNoRowsOverlay,
        }}
        rowCount={count}
        page={page}
        onPageChange={(e) => handleChangePage(e)}
        checkboxSelection={checkboxSelection}
        onSelectionModelChange={(selectionModel) =>
          handleSelectedRows(selectionModel)
        }
        selected={selected}
      />
    </Box>
  );
}
DataTable.propTypes = {
  count: PropTypes.number,
  rows: PropTypes.array,
  columns: PropTypes.array,
  pageSize: PropTypes.number,
  page: PropTypes.number,
  setPage: PropTypes.func,
  checkboxSelection: PropTypes.bool,
};
DataTable.defaultProps = {
  rows: [],
  columns: [],
  pageSize: 10,
  checkboxSelection: false,
};
