import React from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { makeStyles } from '@material-ui/styles';
import { Button } from '@material-ui/core';

const useStyles = makeStyles({
  button: {
    height: '45px',
    borderRadius: '15px',
  },
});

const RedirectButton = ({ title, icon, path, color, ...props }) => {
  const history = useHistory();
  const classes = useStyles();

  return (
    <>
      <Button
        className={classes.button}
        color={color}
        align="right"
        variant="contained"
        startIcon={icon}
        onClick={() => history.push(path)}
        {...props}
      >
        {title}
      </Button>
    </>
  );
};

RedirectButton.propTypes = {
  title: PropTypes.string,
  path: PropTypes.string,
  color: PropTypes.string,
  //   icon: PropTypes.func,
};

RedirectButton.defaultProps = {
  color: 'primary',
  //   icon: PropTypes.func,
};

export default RedirectButton;
