import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { Button } from '@material-ui/core';

const MuiFileInput = ({ setFile, label, variant, acceptType }) => {
  const inputFieldRef = useRef(null);

  const handleUploadFile = () => {
    inputFieldRef.current.click();
  };

  return (
    <>
      <input
        accept={acceptType}
        hidden
        type="file"
        onChange={({ target }) => setFile(target.files[0])}
        ref={inputFieldRef}
      />
      <Button
        color="primary"
        variant={variant}
        component="span"
        onClick={handleUploadFile}
      >
        {label}
      </Button>
    </>
  );
};

MuiFileInput.propTypes = {
  setFile: PropTypes.func,
  label: PropTypes.string,
  variant: PropTypes.string, // contained, outlined
  acceptType: PropTypes.string,
};

MuiFileInput.defaultProps = {
  acceptType: 'image/*',
};

export default MuiFileInput;
