export const colors = {
  primary: '#2e3192',
  secondary: '#878787',
  light: '#FFFFFF',
  chartsColors: {
    primary: '#0f123f',
    secondary: '#fec045',
  },
  borderColor: '#BFBFBF',
  background: {
    primary: '#F8F8F8',
    secondary: '#E5E5E5',
    light: '#FFF',
    lightGreen: '#F5FCF5',
    lightBlue: '#c2e7f5',
  },
  textColor: {
    primary: '#424242',
    secondary: '#353535',
    textPrimary: '#2e3192',
    dark: '#7F7F7F',
    light: '#ffffff',
    lightGray: '#a5a5a5',
    gray: '#93979a',
    lightGreen: '#09be3d',
    lightBlue: '#29abe2',
    darkBlue: '#2e3192',
  },
  chipBgColors: {
    sent: '#136dfa',
    draft: '#adb5bd',
    paid: '#58ca97',
    cancel: '#dd4145',
  },
  iconColors: {
    gray: '#72757a',
  },
};
