import React from 'react';
import PropTypes from 'prop-types';
import { Route } from 'react-router-dom';
import PublicLayout from '../publicLayout';

const PublicRoute = ({ component: Component, ...rest }) => (
  <>
    <PublicLayout>
      <Route {...rest} render={(props) => <Component {...props} />} />
    </PublicLayout>
  </>
);

PublicRoute.propTypes = {
  component: PropTypes.elementType.isRequired,
};

export default PublicRoute;
